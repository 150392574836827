<template>
  <div class="trade-tab">
    <template v-if="isPositionControlsShown">
      <div class="trade-tab__field">
        <input
          v-model="takeProfit"
          class="range--take-profit"
          type="range"
          :min="addPercent(chartStore.newPosition.startPrice, -minMaxPercent)"
          :max="addPercent(chartStore.newPosition.startPrice, minMaxPercent)"
        >
      </div>

      <div class="trade-tab__field">
        <input
          v-model="safe"
          class="range--safe"
          type="range"
          :min="addPercent(chartStore.newPosition.startPrice, -minMaxPercent)"
          :max="addPercent(chartStore.newPosition.startPrice, minMaxPercent)"
        >
      </div>

      <div class="trade-tab__field">
        <input
          v-model="stopLoss"
          class="range--stop-loss"
          type="range"
          :min="addPercent(chartStore.newPosition.startPrice, -minMaxPercent)"
          :max="addPercent(chartStore.newPosition.startPrice, minMaxPercent)"
        >
      </div>

      <div class="trade-tab__profit">
        <span>Amount: ${{ (quantity * chartStore.realtimeCandle.close).toFixed(2) }}</span>
        <span>Profit: ${{ profitAndLoss.profit.toFixed(2) }}</span>
        <span>Loss: ${{ profitAndLoss.loss.toFixed(2) }}</span>
      </div>

      <ui-button
        :type="1"
        class="trade-tab__submit"
        :disabled="enteringPositionInProgress"
        @click="enterPosition"
      >
        Enter position
        <Spinner
          v-if="enteringPositionInProgress"
          :size="16"
        />
      </ui-button>
    </template>

    <ui-button
      v-else
      :type="2"
      @click="createNewPosition"
    >
      Create position
    </ui-button>
  </div>
</template>

<script>
import { calcProfitAndLoss } from '@aitt/shared/math'

import api from '../api'

import UiButton from '../ui/UiButton'
import UiInput from '../ui/UiInput'
import Spinner from '../components/Spinner'

import { useChartStore } from '../store/chart'

const chartStore = useChartStore()

export default {
  components: {
    UiButton,
    UiInput,
    Spinner,
  },

  props: {
    direction: {
      default: 1,
      type: Number,
    },
  },

  data() {
    return {
      chartStore: chartStore,

      /**
       * This equals ~50$ at btc price $20k
       */
      quantity: 0.00250,

      /**
       * Disabled state for Enter position button.
       * Used for loading state
       */
      enterPositionDisabledState: false,

      /**
       * Progress indicator for entering in position
       */
      enteringPositionInProgress: false,

      /**
       * Used in min/max value of a sliders
       */
      minMaxPercent: 0.015,
    }
  },

  computed: {
    activePatternId() {
      return chartStore.activePatternId
    },

    takeProfit: {
      get() {
        return Math.round(chartStore.getNewPosition.take)
      },
      set(val) {
        chartStore.setNewPosition({
          ...chartStore.newPosition,
          shown: true,
          take: val,
        })
      },
    },

    stopLoss: {
      get() {
        return Math.round(chartStore.getNewPosition.stop)
      },
      set(val) {
        chartStore.setNewPosition({
          ...chartStore.newPosition,
          shown: true,
          stop: val,
        })
      },
    },

    safe: {
      get() {
        return Math.round(chartStore.getNewPosition.safe)
      },
      set(val) {
        chartStore.setNewPosition({
          ...chartStore.newPosition,
          shown: true,
          safe: val,
        })
      },
    },

    profitAndLoss() {
      const res = calcProfitAndLoss({
        quantity: this.quantity,
        price: chartStore.realtimeCandle.close,
        stopPrice: this.stopLoss,
        safePrice: this.safe,
        takePrice: this.takeProfit,
      })

      return res
    },

    isPositionControlsShown() {
      return chartStore.newPosition.shown
    },

  },

  watch: {
    direction(val) {
      chartStore.setNewPosition({
        ...chartStore.newPosition,
        direction: this.direction,
      })

      chartStore.calcInitialPositionValues()
    },

    /**
     * Watch for active pattern id & load pattern prices
     */
    async activePatternId(patternId) {
      const pattern = await chartStore.loadPatternPrices(chartStore.activePatternId)

      chartStore.setActivePattern(pattern)

      console.log('---> active pattern id:', patternId, 'data:', pattern)

      chartStore.setNewPosition({
        shown: true,
        startDate: pattern.rightShoulderBegin.date,
        startPrice: pattern.rightShoulderBegin.price,
        direction: this.direction,
      })

      chartStore.calcInitialPositionValues()
    },
  },

  mounted() {
    if (this.$route.query.patternId) {
      chartStore.setActivePatternId(this.$route.query.patternId)
    }
  },

  methods: {
    async enterPosition() {
      const { stop, safe, take } = chartStore.getNewPosition

      const positionParams = {
        ticker: chartStore.brokerData.ticker,
        direction: this.direction,
        quantity: this.quantity,
        takePrice: take,
        safePrice: safe,
        stopPrice: stop,
        patternId: this.$route.query.patternId,
      }

      console.log('----> entering position', positionParams)

      this.enteringPositionInProgress = true

      try {
        await api.createPositionWithSafe(positionParams)

        this.$toast.success('Position created', { timeout: 2000 })

        chartStore.setNewPosition({
          shown: false,
        })
      } catch (e) {
        this.$toast.error(e?.response?.data)
      } finally {
        this.enteringPositionInProgress = false
      }
    },

    createNewPosition() {
      chartStore.setNewPosition({
        shown: true,
        /** Now by default startDate equal `0` & endDate is super big number */
        // startDate: chartStore.realtimeCandle.date,
        startPrice: chartStore.realtimeCandle.close,
        direction: this.direction,
      })

      chartStore.calcInitialPositionValues()
    },

    addPercent(a, p) {
      return a + (a * p)
    },
  },
}
</script>

<style lang="stylus" scoped>
@import '../styles/global.styl'

.trade-tab
  display flex
  flex-direction column
  gap 8px

  &__field
    margin-bottom 12px

    input
      width 100%

  &__submit
    margin-top 10px

  &__profit
    display flex
    justify-content space-between
    margin-bottom 6px
    font-size 15px

.range--stop-loss
  stylizeRange(rgb(199, 33, 24), var(--c-tints-system-red))

.range--take-profit
  stylizeRange(rgb(6, 108, 217), var(--c-accent))

.range--safe
  stylizeRange(rgb(225, 198, 42), var(--c-tints-system-yellow))

</style=>
